import { useSnackbar } from 'notistack';

export const useMessage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showMessage = (message: string) => {
    enqueueSnackbar({
      message,
      position: 'top-right'
    } as any);
  }

  return {
    showMessage,
  }
}