import { User } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../base";
import { useEffect, useState } from "react";

export interface IGroup {
  admins: string[],
  users: string[],
  name: string,
  owner: string,
  isAdmin?: boolean,
  isOwner?: boolean,
  id: string,
  allUsers: [],
}

export interface IUser {
  uid: string,
  name: string,
  email: string,
  isAdmin?: boolean,
  isOwner?: boolean,
}

export type ShortcutsType = IGroup | undefined;

const MAX_GROUP_COUNT = 10;

export const useGroups = (currentUser: User | null) => {
  const [groups, setGroups] = useState<IGroup[]>([]);

  const leaveGroup = (uid: string, group: IGroup) => {
    const admins = group.admins.filter((id) => id !== uid);
    const users = group.users.filter((id) => id !== uid);

    try {
      const groupRef = doc(collection(db, 'groups'), group.id);
      updateDoc(groupRef, { users, admins });
    } catch (e) {
      console.log(e);
    }
  }

  const toggleRule = (uid: string, group: IGroup) => {
    let admins = group.admins;
    let users = group.users;

    if (group.admins.includes(uid)) {
      admins = admins.filter((id) => id !== uid);
    } else if (group.users.includes(uid)) {
      admins = [...admins, uid];
    }

    try {
      const groupRef = doc(collection(db, 'groups'), group.id);
      updateDoc(groupRef, { users, admins });
    } catch (e) {
      console.log(e);
    }
  }

  const getUsersByGroup = async (group: IGroup): Promise<IUser[]> => {
    try {
      const users = [
        group.owner,
        ...group.admins,
        ...group.users,
      ];

      const myCollectionRef = collection(db, "users");
      const q = query(myCollectionRef, where('user', 'in', users));

      return (await getDocs(q)).docs.map((u) => {
        const user = u.data();

        return {
          uid: user.user,
          name: user.username,
          email: user.email,
          isOwner: user.user === group.owner,
          isAdmin: group.admins?.includes(user.user) ?? false,
        }
      });
    } catch (error) {
      return [];
    }
  };

  const createGroup = async (name: string): Promise<[string, boolean]> => {
    try {
      const myCollectionRef = collection(db, "groups");

      const q1 = query(myCollectionRef, where('owner', '==', currentUser?.uid));
      const q2 = query(myCollectionRef, where('name', '==', name));

      const [data1, data2] = await Promise.all([
        getDocs(q1),
        getDocs(q2),
      ])

      if (data1.docs?.length > MAX_GROUP_COUNT) {
        return [`Maximum group count is ${MAX_GROUP_COUNT}`, false];
      }

      if (data2.docs?.length) {
        return [`Group name should be unique.`, false];
      }

      await addDoc(collection(db, "groups"), {
        name,
        owner: currentUser?.uid,
        users: [],
        admins: [],
      });

      return ['Group was added', true];
    } catch {
      return ['Error', false];
    }
  }

  const deleteGroup = (documentId: string) => {
    if (window.confirm('You want delete this group?')) {
      deleteDoc(doc(db, "groups", documentId)).catch(console.log);
    }
  }

  useEffect(() => {
    let unsubscribe: any;
    (async function () {
      if (currentUser) {
        // TODO refactoring
        const myCollectionRef = collection(db, "groups");
        unsubscribe = onSnapshot(myCollectionRef, (snapshot) => {
          const groups = snapshot
            .docs
            .map(i => {
              const data = i.data();

              return {
                ...data,
                id: i.id,
                isAdmin: data.admins.includes(currentUser.uid),
                isOwner: data.owner === currentUser.uid,
              }
            })
            .filter((data: any) => {
              return (
                data.users.includes(currentUser.uid)
                || data.isAdmin
                || data.isOwner
              );
            }) as IGroup[];

            setGroups(groups);
        });
      }
    })();

    return () => {
      unsubscribe?.();
    }
  }, []);

  return {
    groups,
    createGroup,
    deleteGroup,
    getUsersByGroup,
    toggleRule,
    leaveGroup,
  }
};

export const useShortcutGroups = (currentUser: User | null) => {
  const [shortcuts, setShortCuts] = useState<[ShortcutsType, ShortcutsType, ShortcutsType]>(
    [undefined, undefined, undefined]
  );

  useEffect(() => {
    let unsubscribe: any;
    (async function () {
      if (currentUser?.uid) {
        const myCollectionRef = collection(db, "homeScreenGroups");
        const q = query(myCollectionRef, where('user', '==', currentUser.uid));
        const response = await getDocs(q);

        if (response.docs?.[0]?.ref) {
          unsubscribe = onSnapshot(response.docs[0].ref, async (snapshot) => {
            const shortcuts = snapshot?.data()?.groups || ['', '', ''];
            const myCollectionRef = collection(db, "groups");

            
            const groupIds = shortcuts.filter((g: any) => g);
            if (groupIds.length) {
              const q = query(myCollectionRef, where('__name__', 'in', groupIds));
              const groups = (await getDocs(q)).docs.map(e => ({
                ...e.data(),
                id: e.id,
              })) as IGroup[];

              setShortCuts(
                shortcuts.map((id: string) => {
                  return groups?.find((group) => group.id === id)
                }),
              );
            }
          });
        }
      }
    })();

    return () => {
      unsubscribe?.();
    }
  }, [currentUser]);

  const onUpdateShortcuts = async (groups: string[]) => {
    if (currentUser?.uid) {
      const myCollectionRef = collection(db, "homeScreenGroups");
      const q = query(myCollectionRef, where('user', '==', currentUser.uid));
      const response = await getDocs(q);

        if (response.docs?.[0]?.ref) {
          updateDoc(response.docs?.[0]?.ref, {
            groups,
          });
        }
    }
  }

  return {
    shortcuts,
    onUpdateShortcuts,
  }
}
