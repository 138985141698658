import { User } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../base";
import { useEffect, useState } from "react";
import { IUser } from "./groups";
import { playSound } from "../utils";

export const DEFAULT_NOTIFICATION = 'tritone.wav';
export const DEFAULT_OTHER_NOTIFICATION = 'bottle-205353.wav';

export const PREDEFINED_SOUNDS = [
  { file: 'tritone.wav', name: 'Tri-tone' },
  { file: 'money.wav', name: 'Money' },
  { file: 'alert1.wav', name: 'Alert type 1' },
  { file: 'alert2.wav', name: 'Alert type 2' },
  { file: 'alert3.wav', name: 'Alert type 3' },
  { file: 'alert4.wav', name: 'Alert type 4' },
  { file: 'alert5.wav', name: 'Alert type 5' },
];

export const PREDEFINED_SOUNDS_OTHER_ALERT = [
  { file: 'bottle-205353.wav', name: 'Bottle' },
  { file: 'ding-126626.wav', name: 'Ding', },
  { file: 'message-13716.wav', name: 'Message' },
];

export const useSettingsNotifications = (currentUser: User | null) => {
  const [sound, setSound] = useState({
    notificationSound: DEFAULT_NOTIFICATION,
    notificationOtherSound: DEFAULT_OTHER_NOTIFICATION,
  });

  const [profile, setProfile] = useState<IUser>();

  useEffect(() => {
    (async function () {
      try {
        const myCollectionRef = collection(db, "users");
        const q = query(myCollectionRef, where('user', '==', currentUser?.uid));
        const user = (await getDocs(q)).docs.map(u => u.data()).find((data) => data.user === currentUser?.uid);

        setSound({
          notificationSound: user?.notification_sound || DEFAULT_NOTIFICATION,
          notificationOtherSound: user?.notification_other_sound || DEFAULT_OTHER_NOTIFICATION,
        });

        setProfile({
          uid: user?.user || currentUser?.uid || '',
          name: user?.username || '',
          email: user?.email || '',
        });
      } catch { }
    })();
  }, [currentUser?.uid]);

  const updateSound = async (key: 'notificationSound' | 'notificationOtherSound', sound: string) => {
    try {
      playSound(sound);

      setSound((state) => ({
        ...state,
        [key]: sound,
      }));

      const q = query(collection(db, 'users'), where('user', '==', currentUser?.uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const docRef = doc.ref;

        const firebaseKey = {
          notificationSound: 'notification_sound',
          notificationOtherSound: 'notification_other_sound',
        }[key]

        try {
          updateDoc(docRef, {
            [firebaseKey]: sound,
          });
        } catch (e) {
          console.log(e);
        }
      }
    } catch { }
  }

  return {
    sound,
    profile,
    updateSound,
  }
};
