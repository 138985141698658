import { useEffect, useRef, useState } from "react";
import "./SlideMenu.css";
import { Menu, X } from "react-feather";
import classNames from "classnames";
import { auth } from "../base";
import { signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";

export default function SlideMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function onSignOutClick() {
    signOut(auth);
  }

  return (
    <>
      <Menu size={40} className="burger" onClick={toggleMenu} />
      <div className={classNames("slide-menu", { open: isOpen })} ref={menuRef}>
        <div className="header">
          <X size={30} className="close-icon" onClick={toggleMenu} />
        </div>
        <div className="body">
          <div className="links">
            <NavLink className={({ isActive, isPending }) =>
              isActive ? "active" : ""
            } to={"/"}>Home</NavLink>
            <NavLink to={"/groups"}>Groups</NavLink>
            <NavLink to={"/settings"}>Settings</NavLink>
            <NavLink to={"/integrations"}>Integrations</NavLink>
          </div>
          <button className="sign-out-btn" onClick={onSignOutClick}>
            Sign Out
          </button>
        </div>
      </div>
    </>
  );
}
