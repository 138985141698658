import { useContext, useMemo } from "react";
import SlideMenu from "../components/SlideMenu";
import './Integrations.css';
import { useIntegrationOctopus } from "../hooks";
import { AuthContext } from "../App";
import { ArrowRight, Copy } from "react-feather";
import { copyToClipboard } from "../utils";
import { useMessage } from "../hooks/message";

export const Integrations = () => {
  const { showMessage } = useMessage();
  const { currentUser } = useContext(AuthContext);
  const { isLoginLoading, octopusToken, octopusNotification, onConnectOctopus, onDisconnectOctopus } = useIntegrationOctopus(currentUser);

  const login = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const login = (e.target as any).login.value;
    const password = (e.target as any).password.value;

    onConnectOctopus(login, password);
  }

  const loginForm = useMemo(() => {
    return (
      <>
        <form className="octopus-form" onSubmit={login}>
          <input placeholder="login" name="login" />
          <input placeholder="password" name="password" />
          <button className="btn" disabled={isLoginLoading}>
            Connect
          </button>
        </form>
      </>
    )
  }, [isLoginLoading]);

  const notifications = useMemo(() => {
    return (
      <>
        {octopusNotification.map(({ title, message, timestamp }) => (
          <div key={`${title}-${timestamp}`} className="octopus-item">
            <div className="octopus-item-row-title">
              <span className="octopus-item-title" dangerouslySetInnerHTML={{ __html: title }} />
              <span className="octopus-item-date">{new Date(timestamp).toLocaleDateString()}</span>
            </div>
            <p className="octopus-item-message" dangerouslySetInnerHTML={{ __html: message }} />
            <button className="octopus-item-copy" onClick={() => {
              copyToClipboard(message);
              showMessage(`Message was copied!`)
            }}>
              <Copy color="white" />
            </button>
          </div>
        ))}
      </>
    );
  }, [octopusNotification, currentUser]);

  return (
    <>
      <SlideMenu />
      <section className="integration-layout">
        <h1 className="integration-title">
          Octopus
          {
            Boolean(octopusToken) && <button onClick={onDisconnectOctopus}><ArrowRight color="white" /></button>
          }
        </h1>
        <section className="octopus">
          <section className="separator" />
          {!octopusToken && <>
            {loginForm}
            <section className="separator" />
          </>}
          <h1>Notifications:</h1>
          {notifications}
        </section>
      </section>
    </>
  );
}
