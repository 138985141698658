import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import "./App.css";
import { User, onAuthStateChanged } from "firebase/auth";
import { auth, db, setupNotifications } from "./base";
import { debounce, extractToken, playSound } from "./utils";
import Loader from "./components/Loader";
import PageNotFound from "./PageNotFound";
import { collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { useNotifications, useOctopus } from "./hooks";
import { Groups } from "./pages/Groups";
import { Integrations } from "./pages/Integrations";
import { Settings } from "./pages/Settings";
import { SnackbarProvider } from 'notistack';
import Header from "./components/Header";

export const AuthContext = React.createContext({ currentUser: null, token: '' } as unknown as {
  currentUser: User | null,
  token: string
});

const broadcastChannel = new BroadcastChannel('notification_channel');
broadcastChannel.onmessage = async (event) => {
  if (event.data.type === 'notification') {
    if (auth.currentUser) {
      const q = query(collection(db, 'users'), where('user', '==', auth.currentUser.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = await doc.data();

        let sound = data.notification_sound || 'tritone.wav';
        if (event.data.title?.includes('Octopustrade')) {
          sound = data.notification_other_sound || 'tritone.wav';
        }

        playSound(sound, data.created);
      }
    }
  }
};

export default function App() {
  const token = useRef(extractToken()).current;
  const [currentUser, setCurrentUser]: any = useState(null);
  const [loading, setLoading]: any = useState(true);
  const debounceSetLoading = debounce(setLoading, 300);

  useNotifications(currentUser);
  useOctopus(currentUser);

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      debounceSetLoading(false);
    });
  }, [currentUser, debounceSetLoading]);

  useEffect(() => {
    let id: any;
    setupNotifications(async (message: any) => {
      if (auth.currentUser) {
        const q = query(collection(db, 'users'), where('user', '==', auth.currentUser.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const doc = querySnapshot.docs[0];
          const data = await doc.data();

          let sound = data.notification_sound || 'tritone.wav';
          if (message?.notification?.title?.includes('Octopustrade')) {
            sound = data.notification_other_sound || 'tritone.wav';
          }

          const audio = new Audio(`/sound/${sound}`);
          audio.play().catch(error => {
            console.log(error);
          });
        }
      }

    }).then(((token) => {
      id = setInterval(async () => {
        if (auth.currentUser) {
          clearInterval(id);
          const q = query(collection(db, 'users'), where('user', '==', auth.currentUser.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0];
            const docRef = doc.ref;

            try {
              await updateDoc(docRef, {
                web_notification_token: token,
              });
            } catch (e) {
              console.log(e);
            }
          }
        }
      }, 1000);
    }));

    return () => {
      clearInterval(id);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={{ currentUser, token }}>
      <SnackbarProvider >
        <div className="app">
          <BrowserRouter>
            {currentUser ? <Header /> : null}
            <Routes>
              <Route path="/" element={currentUser ? <Home /> : <Login />} />
              <Route path="/settings" element={currentUser ? <Settings /> : <Login />} />
              <Route path="/groups" element={currentUser ? <Groups /> : <Login />} />
              <Route path="/integrations" element={currentUser ? <Integrations /> : <Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Login />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </div>
      </SnackbarProvider>
    </AuthContext.Provider>
  );
}
