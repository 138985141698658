import { useState, useContext, useEffect, useRef } from "react";
import { db } from "../base";
import "./Home.css";
import { AuthContext } from "../App";
import { debounce, getUTC } from "../utils";
import { collection, addDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import classNames from "classnames";
import SlideMenu from "../components/SlideMenu";
import { Bell, Folder, List, Plus, X } from "react-feather";
import Modal from 'react-modal';
import { IGroup, useGroups, useShortcutGroups } from "../hooks";
import { Dnd } from "../components/Dnd";

export default function Home() {
  const { currentUser, token } = useContext(AuthContext);

  const { shortcuts, onUpdateShortcuts } = useShortcutGroups(currentUser);
  const { groups } = useGroups(currentUser);

  const [loading, setLoading] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenGroupControlModal, setIsOpenGroupControlModal] = useState(false);

  const [text, setText] = useState('');
  const [group, setGroup] = useState<IGroup | null>();
  const debounceSetLoading = debounce(setLoading, 300);
  const refIsAdded = useRef(false);
  const refIsAddedError = useRef(false);

  const ids = groups
    .filter(({ isAdmin, isOwner }) => isAdmin || isOwner)
    .map(({ id }) => id);

  function addNotification(ids: string[], type: 'withSound' | 'withOtherSound' = 'withSound') {
    return async () => {
      try {
        if (!currentUser) {
          return;
        }
        setLoading(true);
        const uid: string = currentUser.uid;
        await addDoc(collection(db, "notifications"), {
          user: uid,
          created: getUTC(),
          device: "browser",
          groupIds: ids,
          type,
          text,
        });
        debounceSetLoading(false);
      } catch { }
      finally {
        setText('');
        setGroup(null);
        setIsOpenModal(false);
      }
    }
  }

  const onSuccess = (name: any) => () => {
    if (refIsAdded.current) return;
    refIsAdded.current = true;
    alert(`You have been added to "${name}" group.`);
  }

  const onError = () => {
    if (refIsAddedError.current) return;
    refIsAddedError.current = true;
    alert('Bad link.');
  }

  const handleDeepLink = async (token: string) => {
    if (!currentUser || !token || refIsAdded.current) return;
    const uid: string = (currentUser as any).uid;
    const groupRef = doc(collection(db, 'groups'), token);

    getDoc(groupRef)
      .then(snapshot => {
        const group = snapshot.data() || {};
        let users = group.users || [];
        if (group.owner !== uid && !users.includes(uid)) {
          users.push(uid);

          updateDoc(groupRef, { users: users })
            .then(onSuccess(group.name))
            .catch(onError);
        }
      })
      .catch(onError);
  };

  useEffect(() => {
    handleDeepLink(token);
  }, []);

  useEffect(() => {
    if (!isOpenModal) {
      setGroup(null);
    }
  }, [isOpenModal]);

  return (
    <>
      <SlideMenu />
      <div className="container">
        <div
          className="send-notifications"
        >
          <div className="radius radius-1" />
          <div className="radius radius-2" />
          <div className="radius radius-3" />
          <div className="radius radius-4" />

          {shortcuts.map((group, i) => (
            <button className={`home-group group-${i + 1}`} onClick={() => {
              if (group?.id) {
                setGroup(group);
                setIsOpenModal(true);
              } else {
                setIsOpenGroupControlModal(true);
              }
            }}>
              {
                group?.name
                  ? <span className="home-group-title truncate">{group?.name}</span>
                  : <Plus color="white" />
              }
            </button>
          ))}

          <button className="home-group group-4" onClick={() => setIsOpenGroupControlModal(true)}>
            <List />
          </button>

          <button className="send-notification" onClick={() => setIsOpenModal(true)} />

          <div
            className={classNames("bounce-loader", { "not-visible": !loading })}
          >
            <div className="loader"></div>
            <div className="loader"></div>
            <div className="loader"></div>
          </div>
          <span className={classNames({ "not-visible": loading })}></span>
        </div>
      </div>

      <Modal
        isOpen={isOpenModal}
        contentLabel='Example Modal'
        style={modalStyles as any}
      >
        <section className="send-notification-modal-container">
          <button className="send-notification-modal-close" onClick={() => setIsOpenModal(false)}>
            <X color="black" />
          </button>
          <section className="send-notification-modal-title">
            <h1>
              Send notification
            </h1>
            <span>
              <Folder color="#2E5EFE" />
              {
                group
                  ? `to ${group.name}`
                  : 'to all groups'
              }
            </span>
          </section>

          <section>
            <input placeholder="Notification text" value={text} onChange={(e) => setText(e.target.value)} />
          </section>

          <button className="btn" onClick={addNotification(group ? [group.id] : ids)} disabled={!text}>
            <Bell /> Send With Sound
          </button>
          <button className="btn out" onClick={addNotification(group ? [group.id] : ids, 'withOtherSound')} disabled={!text}>
            <Bell color="#2E5EFE" /> Send push
          </button>
        </section>
      </Modal>

      <Modal
        isOpen={isOpenGroupControlModal}
        contentLabel=''
        style={modalGroupStyles as any}
      >
        <section className="send-notification-modal-container">
          <button className="send-notification-modal-close" onClick={() => setIsOpenGroupControlModal(false)}>
            <X color="white" />
          </button>
          <section className="send-notification-modal-title">
            <h1 className="white">
              More Group
            </h1>
          </section>

          <div style={{ height: 'calc(100% - 130px)', marginBottom: 24, marginTop: 24 }}>
            <Dnd groups={groups} shortcuts={shortcuts} onUpdateShortcuts={onUpdateShortcuts} onPing={(group) => {
              setGroup(group);
              setIsOpenModal(true);
              setIsOpenGroupControlModal(false);
            }} />
          </div>



          <button style={{ marginBottom: 24 }} className="btn out" onClick={() => setIsOpenGroupControlModal(false)}>
            Cancel
          </button>
        </section>
      </Modal>
    </>
  );
}

const modalStyles = {
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(5px)',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10001,
  },
  content: {
    position: 'static',
    maxWidth: 620,
    maxHeight: 320,
    width: '100%',
    height: '100%',
  }
}

const modalGroupStyles = {
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(5px)',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10001,
  },
  content: {
    position: 'static',
    maxWidth: 620,
    maxHeight: '80vh',
    width: '100%',
    height: '100%',
    background: '#1D2A54',
    paddingBottom: 24,
  }
}