import { useContext } from "react";
import "./Header.css";
import { LogOut, Home, Settings, Folder, Globe } from "react-feather";
import { auth } from "../base";
import { signOut } from "firebase/auth";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../App";
import { useSettingsNotifications } from "../hooks";

export default function Header() {
  const { currentUser } = useContext(AuthContext);
  const { profile } = useSettingsNotifications(currentUser);

  return (
    <section className="navigation-header">
      <div className="logo">
        <img src="/secondChance.png" />
      </div>
      <nav className="nav-links">
        <NavLink to={"/groups"}><Folder />Groups</NavLink>
        <NavLink to={"/"}><Home />Home</NavLink>
        <NavLink to={"/settings"}><Settings />Settings</NavLink>
        <NavLink to={"/integrations"}><Globe />Integrations</NavLink>
      </nav>
      <div className="header-actions">
        <span className="truncate">
          {profile?.name}
        </span>
        <button onClick={() => {
          signOut(auth);
          window.location.href = '/';
        }}>
          <LogOut color="white" />
        </button>
      </div>
    </section>
  );
}
