import { User } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../base";
import { useEffect, useState } from "react";
import { useMessage } from "./message";

export interface IOctopusNotification {
  title: string,
  message: string,
  timestamp: number,
}

export const useIntegrationOctopus = (currentUser: User | null) => {
  const { showMessage } = useMessage();

  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [octopusToken, setOctopusToken] = useState('');
  const [octopusNotification, setOctopusNotifications] = useState<IOctopusNotification[]>([]);

  const onConnectOctopus = async (login: string, password: string) => {
    if (currentUser?.uid) {

      setIsLoginLoading(true);
      try {
        const response = await fetch("https://octopustrades.live/api/auth/login", {
          method: 'post',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "username": login,
            "password": password,
          })
        }).then((response) => response.text());

        if (response.includes('token')) {
          const authToken = JSON.parse(response).token;
          setOctopusToken(authToken);

          const myCollectionRef = doc(db, 'octopustrade', currentUser?.uid);
          await updateDoc(myCollectionRef, { authToken });
        } else {
          showMessage('Login or password incorrect')
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoginLoading(false);
      }
    }
  }

  const onDisconnectOctopus = () => {
    try {
      if (currentUser?.uid && window.confirm('Is disconnect Octopus?')) {
        const myCollectionRef = doc(db, 'octopustrade', currentUser?.uid);
        updateDoc(myCollectionRef, { authToken: '' });
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    (async function () {
      try {
        setIsLoginLoading(true);
        if (currentUser?.uid) {
          const myCollectionRef = doc(db, 'octopustrade', currentUser?.uid);
          const docSnap = await getDoc(myCollectionRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setOctopusToken(data.authToken || '');
          } else {
            const myCollectionRef = doc(db, 'octopustrade', currentUser?.uid);
            await setDoc(
              myCollectionRef,
              {
                authToken: '',
                notification: [],
              }
            )
          }
        }
      } catch { } finally {
        setIsLoginLoading(false);
      }
    })();
  }, [currentUser?.uid]);

  useEffect(() => {
    let unsubscribeNotification: any;
    (async function () {
      if (currentUser) {
        const myCollectionRef = collection(db, "octopustrade");
        let token = '';
        unsubscribeNotification = onSnapshot(myCollectionRef, (snapshot) => {
          const notifications = snapshot
            .docs
            .filter((i) => i.id === currentUser.uid)
            .map(i => {
              const data = i.data();
              token = data.authToken;
              return data.notification;
            })
            .reduce((acc, notification) => ([
              ...acc,
              notification,
            ])) as IOctopusNotification[];

          setOctopusToken(token);
          setOctopusNotifications(notifications);
        });
      }
    })();

    return () => {
      unsubscribeNotification?.();
    }
  }, [currentUser?.uid]);

  return {
    octopusToken,
    isLoginLoading,
    octopusNotification,
    onConnectOctopus,
    onDisconnectOctopus,
  }
};
