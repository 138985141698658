import { group } from 'console';
import { IGroup, ShortcutsType } from '../hooks';
import './Dnd.css';
import { Bell, Folder, Plus } from 'react-feather';
import { useEffect, useRef, useState } from 'react';

export const Dnd = ({
  shortcuts,
  groups,
  onPing,
  onUpdateShortcuts,
}: {
  shortcuts: ShortcutsType[],
  groups: IGroup[],
  onPing: (group: IGroup) => void,
  onUpdateShortcuts: (value: string[]) => Promise<void>
}) => {
  const [group, setGroup] = useState<IGroup | null>(null);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  })
  const isDragRef = useRef(false);
  const zeroPosition = useRef({
    x: 0,
    y: 0,
    clientX: 0,
    clientY: 0,
    currentX: 0,
    currentY: 0,
  });

  const dropZone = useRef({
    startY: 0,
    endY: 0,
  })

  const onPress = (e: React.MouseEvent, group: IGroup) => {
    const wrapper = document.querySelector('.dnd')?.getBoundingClientRect();
    const target = document.querySelector(`#id${group.id}`)?.getBoundingClientRect();

    if (wrapper && target) {
      zeroPosition.current.x = target.x - wrapper.x;
      zeroPosition.current.y = target.y - wrapper.y;
      zeroPosition.current.clientX = e.clientX;
      zeroPosition.current.clientY = e.clientY;
      zeroPosition.current.currentX = target.x - wrapper.x;
      zeroPosition.current.currentY = target.y - wrapper.y;
  
      setPosition({
        x: zeroPosition.current.x,
        y: zeroPosition.current.y,
      });
  
      setGroup(group);
    }
  }

  const onOut = () => {
    setGroup((group) => {
      if (group && dropZone.current.startY < zeroPosition.current.currentY && zeroPosition.current.currentY < dropZone.current.endY) {
        const newShortcuts = [group, ...shortcuts].splice(0, 3);
        onUpdateShortcuts(newShortcuts.map(g => g?.id || ''))
      }

      return null;
    });

    
  }

  const onMove = (e: MouseEvent) => {
    if (isDragRef.current) {
      const x = zeroPosition.current.x;
      const y = zeroPosition.current.y - (zeroPosition.current.clientY - e.clientY);

      zeroPosition.current.currentX = x;
      zeroPosition.current.currentY = y;

      setPosition({ x,  y });
    }
  }

  useEffect(() => {
    isDragRef.current = Boolean(group);
  }, [group]);

  useEffect(() => {
    window.addEventListener('mouseup', onOut);
    window.addEventListener('mousemove', onMove);

    return () => {
      window.removeEventListener('mouseup', onOut);
    }
  }, [shortcuts]);

  useEffect(() => {
    const wrapper = document.querySelector('.dnd')?.getBoundingClientRect();
    const dropElement = document.querySelector('.shortcuts');
    if (dropElement && wrapper) {
      const { y, height } = dropElement.getBoundingClientRect();
      dropZone.current.startY = y - wrapper.y;
      dropZone.current.endY = y - wrapper.y + height - 24;
    }
  });

  return (
    <section className='dnd'>
      {group && <div
        key={group.id}
        className='group move'
        style={{
          top: position.y,
          left: position.x
        }}
      >
        <Folder color='#2E5EFE' />
        <span className='name truncate'>{group.name}</span>
        <div style={{ flex: 1 }} />
        <button className='btn out' onClick={() => onPing(group)}>
          <Bell color='black' size={14} />
          Ping
        </button>
      </div>}

      <section className='shortcuts' style={{
        opacity: group && dropZone.current.startY < position.y && position.y < dropZone.current.endY
          ? 0.5
          : 1
      }}>
        {shortcuts.map((group, index) => {
          if (group) {
            return (
              <div key={group?.id} className='group'>
                <Folder color='#2E5EFE' />
                <span className='name truncate'>{group?.name}</span>
                <div style={{ flex: 1 }} />
                <button className='btn out' onClick={() => onPing(group)}>
                  <Bell color='black' size={14} />
                  Ping
                </button>
              </div>
            )
          }

          return <div className='empty-group'>
            <Plus color='#7D9BFF' />
          </div>
        })}
      </section>

      <section className='all-groups'>
        {groups.filter((g) => !shortcuts.find(short => short?.id === g.id)).map(_group => {
          return (
            <div key={_group.id} style={{ opacity: _group.id === group?.id ? 0 : 1 }} className='group' id={`id${_group.id}`} onMouseDown={(e) => {
              onPress(e, _group);
            }}>
              <Folder color='#2E5EFE' />
              <span className='name truncate'>{_group.name}</span>
              <div style={{ flex: 1 }} />
              <button className='btn out' onClick={(e) => {
                e.stopPropagation();
                onPing(_group);
              }}>
                <Bell color='black' size={14} />
                Ping
              </button>
            </div>
          )
        })}
      </section>
    </section>
  );
}