export function debounce(func: any, delay: any) {
  let timerId: any;

  return function (...args: any) {
    clearTimeout(timerId);

    timerId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
}

export function getFirebaseErrorText(error: any) {
  switch (error.code) {
    case "auth/invalid-email":
      return { type: "email", msg: "Email is invalid." };
    case "auth/email-already-in-use":
      return { type: "email", msg: "Email already in use." };
    case "auth/user-not-found":
      return { type: "email", msg: "User is not found." };
    case "auth/missing-password":
      return { type: "password", msg: "Password is required." };
    case "auth/weak-password":
      return { type: "password", msg: "Password is weak." };
    case "auth/wrong-password":
      return { type: "password", msg: "Password is wrong." };
    default:
      return { type: null, msg: error.code };
  }
}

export function getUTC() {
  const currentDate = new Date();
  return currentDate.getTime();
}

export function extractToken() {
  if (window.location.href.includes('add-group')) {
    return window?.location?.href?.split?.('/')?.pop?.() || '';
  }

  return '';
}

export function pathHelper(user?: any) {
  return user ? '/' : '/login';
}

export function sendPostMessage(data: any) {
  try {
    if (window.chrome && window.chrome.webview) {
      window.chrome.webview.postMessage(JSON.stringify(data));
    } else {
      console.log("WebView2 not detected.");
    }
  } catch (e) {
    console.log(e);
  }
}

declare global {
  interface Window {
    chrome: any;
  }
}

export const playSound = (name: string, time = -1 ): Promise<Boolean> => {
  return new Promise(result => {
    try {
      const audio = new Audio(`/sound/${name}`);

      if (time > 0 && !localStorage.getItem(`${time}`)) {
        localStorage.setItem(`${time}`, 'true');

        return audio.play()
          .then(() => result(true))
          .catch(() => result(false))
          .finally(() => setTimeout(() => {
            audio.pause()
          }, 1000));
      } else if (time < 0) {
        return audio.play()
          .then(() => result(true))
          .catch((e) => {
            console.log(e);
            result(false)
          }).finally(() => setTimeout(() => {
            audio.pause()
          }, 1000));;
      }

      return false;
    } catch { }
  })
}

export function copyToClipboard(text: string) {
  const textarea = document.createElement('textarea');
  textarea.value = text;

  textarea.style.position = 'fixed';
  textarea.style.opacity = '0';
  document.body.appendChild(textarea);

  textarea.select();
  document.execCommand('copy');

  document.body.removeChild(textarea);
}