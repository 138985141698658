import { ArrowRight, User, Bell } from "react-feather";
import SlideMenu from "../components/SlideMenu";
import './Settings.css';
import { useContext, useState } from "react";
import { PREDEFINED_SOUNDS, PREDEFINED_SOUNDS_OTHER_ALERT, useSettingsNotifications } from "../hooks/index";
import { AuthContext } from "../App";

export const Settings = () => {
  const { currentUser } = useContext(AuthContext);
  const {
    sound: { notificationSound, notificationOtherSound },
    profile,
    updateSound,
  } = useSettingsNotifications(currentUser);

  return (
    <>
      <SlideMenu />
      <section className="settings-layout">
        <h1>Settings</h1>
        <section className="settings-menu">
          <details className="settings-wrapper">
            <summary className="settings-item">
              <span className="name">
                <User color="#2E5EFE" />
                My profile
              </span>
              <ArrowRight className="settings-arrow" color="white" />
            </summary>
            <div className="settings-details profile">
              <input placeholder="username" name="username" readOnly value={profile?.name} />
              <input placeholder="email" name="email" readOnly value={profile?.email} />
              <button disabled>Save</button>
            </div>
          </details>

          <details className="settings-wrapper">
            <summary className="settings-item">
              <span className="name">
                <Bell color="#2E5EFE" />
                Notifications
              </span>
              <ArrowRight className="settings-arrow" color="white" />
            </summary>
            <div className="settings-details">
              <section className="settings-notification-wrapper first">
                <p className="settings-notification-title">
                  Notifications Sound
                </p>

                {PREDEFINED_SOUNDS.map(({ file, name }) => (
                  <label key={file} className="notification-item">
                    <input type="radio" name="notification" checked={file === notificationSound} onChange={() => updateSound('notificationSound', file)} />
                    {name}
                  </label>
                ))}
              </section>
            </div>
            <div className="settings-details">
              <section className="settings-notification-wrapper">
                <p className="settings-notification-title">
                  Other Notifications Sound
                </p>

                {PREDEFINED_SOUNDS_OTHER_ALERT.map(({ file, name }) => (
                  <label key={file} className="notification-item">
                    <input type="radio" name="other-notification" checked={file === notificationOtherSound} onChange={() => updateSound('notificationOtherSound', file)} />
                    {name}
                  </label>
                ))}
              </section>
            </div>
          </details>
        </section>
      </section>
    </>
  );
}
