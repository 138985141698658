import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../App";
import SlideMenu from "../components/SlideMenu";
import { IUser, useGroups } from "../hooks/groups";
import './Groups.css';
import { Trash, ArrowLeft, ArrowRight, LogOut, Share, Folder, X, Copy, Plus, Minus } from "react-feather";
import { useMessage } from "../hooks/message";
import Modal from 'react-modal';
import { QRCodeSVG } from 'qrcode.react'
import { copyToClipboard } from "../utils";

export const Groups = () => {
  const { showMessage } = useMessage();
  const { currentUser } = useContext(AuthContext);
  const { groups, createGroup, deleteGroup, getUsersByGroup, toggleRule, leaveGroup } = useGroups(currentUser);
  const [disabledAdd, setDisabledAdd] = useState(false);
  const [groupId, setGroupId] = useState<string | null>(null);
  const [isShare, setIsShare] = useState(false);
  const [users, setUser] = useState<IUser[]>([]);

  const group = groups?.find(({ id }) => id === groupId);

  useEffect(() => {
    (async function () {
      if (group) {
        const users = await getUsersByGroup(group);
        setUser(users);
      }
    })();
  }, [group, groups]);

  const onCreate = async (e: React.FormEvent<HTMLFormElement>) => {
    setDisabledAdd(true);
    try {
      e.preventDefault();
      const groupName = (e.target as any).groupName.value;

      if (groupName) {
        const [text, status] = await createGroup(groupName);
        showMessage(text);
        if (status) {
          (e.target as any).groupName.value = '';
        }
      }
    } catch { }

    setDisabledAdd(false);
  }

  const allGroups = useMemo(() => {
    return <section className="groups">
      <form onSubmit={onCreate} className="groups-create">
        <h1>Groups</h1>
        <input required name="groupName" placeholder="Group name" />
        <button disabled={disabledAdd}>Create Group</button>
      </form>
      <div className="separator" />
      {groups.map(({ name, isAdmin, isOwner, id, ...other }) => (
        <div key={id} className="group-item">
          <span className="name truncate"><Folder color="#2E5EFE" />{name}</span>
          {
            (isAdmin || isOwner) && (<button className="admin-button" onClick={() => setGroupId(id)}>
              {isAdmin && <span className="title">Admin</span>}
              {isOwner && <span className="title">Owner</span>}
              <ArrowRight color="white" />
            </button>)
          }
          {!isOwner && (
            <button onClick={() => {
              if (currentUser?.uid && window.confirm(`Do you want leave from group (${name})?`)) {
                leaveGroup(currentUser?.uid, { name, isAdmin, isOwner, id, ...other });
              }
            }}>
              <LogOut color="#7995f0" />
            </button>
          )}
        </div>
      ))}
    </section>
  }, [groups, disabledAdd]);

  const singleGroup = useMemo(() => {
    const group = groups.find(({ id }) => groupId === id);
    if (group) {
      const { name } = group;
      const userCount = users.length;

      return (
        <section className="group">
          <section className="group-title">
            <button className="group-back" onClick={() => setGroupId(null)}>
              <ArrowLeft color="white" />
            </button>
            <h1>{name}</h1>
            <div className="group-action">
              {currentUser?.uid === group.owner && (
                <button onClick={() => {
                  if (groupId) {
                    deleteGroup(groupId);
                    setGroupId(null);
                  };
                }}>
                  <Trash color="red" />
                </button>)
              }
              {
                currentUser?.uid !== group.owner && (
                  <button onClick={() => {
                    if (currentUser?.uid && window.confirm(`Do you want leave from group (${group.name})?`)) {
                      leaveGroup(currentUser?.uid, group);
                      setGroupId(null);
                    }
                  }}>
                    <LogOut color="#7995f0" />
                  </button>
                )
              }
            </div>
          </section>
          <section className="invite">
            <button onClick={() => setIsShare(true)}>
              <Share />
              Invite to Group
            </button>
            <p className="users-added">
              {userCount} users added
            </p>
          </section>
          <section className="users">
            {users.map(({ name, isAdmin, isOwner, uid }) => (
              <div key={uid} className="group-item">
                <span className="name">{name}</span>
                {
                  uid === currentUser?.uid && (<button className="admin-button">
                    <span className="title">
                      {`YOU ${group.admins.includes(currentUser?.uid ?? '') ? '(admin)' : ''}`}
                    </span>
                  </button>)
                }
                {
                  group.isOwner && !isAdmin && currentUser?.uid !== uid && (
                    <button className="admin-permission" onClick={() => toggleRule(uid, group)}>
                      <Plus /> Add Admin Role
                    </button>
                  )
                }
                {
                  group.isOwner && currentUser?.uid !== uid && isAdmin && (
                    <button className="admin-permission" onClick={() => toggleRule(uid, group)}>
                      <Minus /> Remove Admin Role
                    </button>
                  )
                }
              </div>
            ))}
          </section>
        </section>
      );
    }

    return null;
  }, [groupId, groups, users]);

  return (
    <>
      <SlideMenu />
      <section className="groups-layout">
        {groupId ? singleGroup : allGroups}
      </section>
      <Modal
        isOpen={isShare}
        contentLabel='Example Modal'
        style={modalStyles as any}
      >
        {
          group ? (
            <section className="share-modal-container">
              <button className="share-modal-close" onClick={() => setIsShare(false)}>
                <X color="black" />
              </button>
              <section className="share-modal-title">
                <h1>
                  Invite to Group
                </h1>
                <p>
                  Scan this QR to the user you want to add to this group.
                </p>
                <span>
                  <Folder color="#2E5EFE" /> {group.name}
                </span>
              </section>
              <section className="qr">
                <QRCodeSVG fgColor='#CB5EFE' height={250} width={250} value={`https://inplay.scprop.trade/universal/add-group/${groupId}`} />
              </section>
              <div className="share-modal-link">
                <span className="truncate">
                  {`https://inplay.scprop.trade/universal/add-group/${groupId}`}
                </span>
                <button onClick={() => {
                  copyToClipboard(`https://inplay.scprop.trade/universal/add-group/${groupId}`);
                  showMessage('Link for group was copied!')
                }}>
                  <Copy color="#2E5EFE" />
                </button>
              </div>
              <button className="btn" onClick={() => setIsShare(false)}>
                Close
              </button>
            </section>
          ) : null
        }
      </Modal>
    </>
  );
}

const modalStyles = {
  overlay: {
    backgroundColor: 'transparent',
    backdropFilter: 'blur(5px)',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: 'static',
    maxWidth: 620,
    maxHeight: 600,
    width: '100%',
    height: '100%',
  }
}