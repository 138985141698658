import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAElJvg1eXhkDT4glgaG_9YzwcNcOHEa7w",
  authDomain: "scalert-fox.firebaseapp.com",
  projectId: "scalert-fox",
  storageBucket: "scalert-fox.appspot.com",
  messagingSenderId: "499598136579",
  appId: "1:499598136579:web:7c04c5dc26483332e60536",
  measurementId: "G-232F4EV1K8",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const messaging = getMessaging(app);
export const setupNotifications = async (cb) => {
  try {
    onMessage(messaging, (payload) => {
      cb?.(payload);
    });

    const permission = await Notification.requestPermission();
    
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      return await getToken(messaging)
    } else {
      return null;
    }

  
  } catch (error) {
    console.error('Error setting up notifications:', error);
  }
};


export default app;
