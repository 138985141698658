import { User } from "firebase/auth";
import { doc, collection, query, where, getDocs, onSnapshot, getDoc, orderBy, limit } from "firebase/firestore";
import { useEffect, useRef } from "react";
import { db } from "../base";
import { playSound, sendPostMessage } from "../utils";
import { IOctopusNotification } from "./octopus";
import { DEFAULT_NOTIFICATION, DEFAULT_OTHER_NOTIFICATION } from "./settings";

export const useNotifications = (currentUser: User) => {
  useEffect(() => {
    let unsubscribeNotification: any;

    (async function () {
      if (currentUser) {
        const myCollectionRef = collection(db, "notifications");
        const q = query(myCollectionRef, orderBy('created', 'desc'), limit(1));

        const querySnapshot = await getDocs(q);
        let lastNotificationTime = Date.now();

        if (!querySnapshot.empty) {
          const lastDoc = querySnapshot.docs[0];
          lastNotificationTime = lastDoc.data().created || Date.now();
        }

        unsubscribeNotification = onSnapshot(myCollectionRef, (snapshot) => {
          snapshot.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              const notification = change.doc.data();

              if (notification.user !== currentUser.uid && notification.created >= lastNotificationTime) {
                const groups = await Promise.all(
                  notification?.groupIds?.map((id: string) => {
                    const docRef = doc(db, 'groups', id);
                    return getDoc(docRef);
                  }) || []
                );

                if (groups?.find((snap) => {
                  if (snap?.exists()) {
                    const data = snap.data();

                    return data?.admins?.includes?.(currentUser.uid)
                      || data?.users?.includes?.(currentUser.uid)
                      || data.owner === currentUser.uid;
                  }

                  return false;
                })) {
                  const q = query(collection(db, 'users'), where('user', '==', currentUser?.uid));
                  const querySnapshot = await getDocs(q);
                  if (!querySnapshot.empty) {
                    const doc = querySnapshot.docs[0];
                    const data = await doc.data();

                    const result = await playSound(
                      data.notification_sound || DEFAULT_NOTIFICATION,
                      notification.created,
                    );

                    const postData = {
                      ...notification,
                      sound: result
                        ? ''
                        : data.notification_sound || DEFAULT_NOTIFICATION,
                    }

                    sendPostMessage(postData);
                  }
                }
              }
            }
          });
        });
      }
    })();

    return () => {
      unsubscribeNotification?.();
    }
  }, [currentUser]);
}

export const useOctopus = (currentUser: User) => {
  const timestamp = useRef(Date.now());

  useEffect(() => {
    if (currentUser) {
      const myCollectionRef = doc(db, 'octopustrade', currentUser?.uid);
      const unsubscribeNotificationOctopus = onSnapshot(myCollectionRef, async (snapshot) => {
        snapshot.data()?.notification.forEach(async (notification: IOctopusNotification) => {
          if (notification.timestamp > timestamp.current) {
            const q = query(collection(db, 'users'), where('user', '==', currentUser?.uid));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
              const doc = querySnapshot.docs[0];
              const data = await doc.data();

              const result = await playSound(
                data.notification_other_sound || DEFAULT_OTHER_NOTIFICATION,
                notification.timestamp,
              );

              const postData = {
                ...notification,
                sound: result
                  ? ''
                  : data.notification_other_sound || DEFAULT_OTHER_NOTIFICATION,
              }

              sendPostMessage(postData);
            }
          }
        });
      });

      return () => {
        unsubscribeNotificationOctopus();
      }
    }

  }, [currentUser]);
}